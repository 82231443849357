import React, { useRef, useState } from "react";
import { motion } from "framer-motion";

import {styles} from '../styles'
import { slideIn } from "../utils/motion";

export default function ResumeDownload() {
  return (
    <>   <div className='mb-10'   id='downloadresume'><h1><br></br></h1></div>
       <div
      className={`mx-2 sm:mx-24 xl:mt-64 mt-12 mt  bg-gradient-to-r  from-indigo-950 to-black rounded-xl flex xl:flex-row flex-col-reverse gap-10 overflow-hidden`}
    >
        
      <motion.div
        variants={slideIn("left", "tween", 0.2, 1)}
        className='flex-[0.75] bg-black-100 p-8 rounded-2xl'
        >
        <p className={styles.sectionSubText}></p>
        <h3 className=" text-white font-black md:text-[50px] sm:text-[50px] xs:text-[40px] text-[30px]">Download Resume</h3>

      <a href="./images/AniketSinghResume.pdf "download="AniketSinghResume.pdf"> <button className="mt-10 bg-white hover:bg-gradient-to-r from-blue-600 via-blue-300
     to-indigo-600  background-animate hover:text-white text-black p-5 w-44 rounded-xl">Download</button></a>
      </motion.div>
      {/* <motion.div
        variants={slideIn("right", "tween", 0.2, 1)}
        className='xl:flex-1 xl:h-auto md:h-[550px] h-[350px]'
      >
       
      </motion.div> */}
      
    </div>
    </>
  )
}

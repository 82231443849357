import React, { useRef, useState } from "react";
import { motion } from "framer-motion";

import {styles} from '../styles'
import { slideIn } from "../utils/motion";

import formData from 'form-data';
import Mailgun from 'mailgun.js';
import Toast from "./Toast";


const Contact = () => {
  const [handletoast,setHandletoast]=useState(false)
const [name,setName]=useState('')
const [email,setEmail]=useState('')
const [message,setMessage]=useState('')

  const triggermail=async(e)=>{
    e.preventDefault();
    const mailgun = new Mailgun(formData);
    const client = mailgun.client({username: 'api', key:"b193222f024837b8445d6c44e1d93ea3-3750a53b-e0c21284"});
    const messageData = {
      from: email,
      to: "aniket.dev.tech@gmail.com",
      subject: 'From portfolio',
      text: message
    };
    
    client.messages.create("mg.anicreate.tech", messageData)
     .then((res) => {
      //  console.log(res);
       setHandletoast(true)
       setName('')
       setEmail('')
       setMessage('')
     })
     .catch((err) => {
      //  console.error(err);
     });
  }
  const handleVerify=async()=>{
setHandletoast(true)
  }
  

  return (
    
   <>
      <div className='mb-10'  id='contact'><h1><br></br></h1></div>
    {/* <div
      className={` xl:mt-12 mt-28 bg-gradient-to-r  from-indigo-950 to-black rounded-xl flex xl:flex-row flex-col-reverse gap-10 overflow-hidden`}
    >
        
      <motion.div
        variants={slideIn("left", "tween", 0.2, 1)}
        className='flex-[0.75] bg-black-100 p-8 rounded-2xl'
        >
        <p className={styles.sectionSubText}>Lets get in Touch</p>
        <h3 className=" text-white font-black md:text-[60px] sm:text-[50px] xs:text-[40px] text-[30px]">Contact Me</h3>

        <form
      onSubmit={triggermail}
      className='mt-12 flex flex-col gap-8'
      >
          <label className='flex flex-col'>
            <span className='text-white font-medium mb-4'>Your Name</span>
            <input
              type='text'
              name='name'
              required
              value={name}
              onChange={(e)=>{setName(e.target.value)}}
              placeholder="What's your good name?"
              className='bg-white bg-opacity-5 py-4 px-6 placeholder:text-secondary text-white rounded-lg outline-none border-none font-medium'
              />
          </label>
          <label className='flex flex-col'>
            <span className='text-white font-medium mb-4'>Your email</span>
            <input
              type='email'
              name='email'
              required
              value={email}
              onChange={(e)=>{setEmail(e.target.value)}}
              placeholder="What's your web address?"
              className='bg-white bg-opacity-5 py-4 px-6 placeholder:text-secondary text-white rounded-lg outline-none border-none font-medium'
              />
          </label>
          <label className='flex flex-col'>
            <span className='text-white font-medium mb-4'>Your Message</span>
            <textarea
              rows={7}
              name='message'
              required
              value={message}
              onChange={(e)=>{setMessage(e.target.value)}}
              placeholder='What you want to say?'
              className='bg-white bg-opacity-5 py-4 px-6 placeholder:text-secondary text-white rounded-lg outline-none border-none font-medium'
              />
          </label>
   
{!handletoast &&   <Toast/>}

          <button
            type='submit'
            className='bg-tertiary py-3 px-8 rounded-xl outline-none w-fit text-black bg-white font-bold shadow-md shadow-primary'
            >
            Submit
          </button>
        </form>
      </motion.div>
      
    </div> */}

    <div className='flex flex-col items-center justify-center'>
      <p>Sorry the contact us function is disabled temporarly</p>
      </div>
   </>
  );
};

export default Contact